// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---atacama-packages-packages-themes-theme-default-src-pages-404-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/404.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-404-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/contact.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/thanks.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js" */),
  "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx" */),
  "component---atacama-packages-packages-web-core-src-pages-info-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/---info.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-info-js" */),
  "component---atacama-packages-packages-web-core-src-templates-page-legal-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-templates-page-legal-js" */),
  "component---src-pages-agb-js": () => import("./../../../src/pages/agb.js" /* webpackChunkName: "component---src-pages-agb-js" */),
  "component---src-pages-altholzentsorgung-js": () => import("./../../../src/pages/altholzentsorgung.js" /* webpackChunkName: "component---src-pages-altholzentsorgung-js" */),
  "component---src-pages-chemiepaletten-js": () => import("./../../../src/pages/chemiepaletten.js" /* webpackChunkName: "component---src-pages-chemiepaletten-js" */),
  "component---src-pages-euro-gitterboxen-js": () => import("./../../../src/pages/euro-gitterboxen.js" /* webpackChunkName: "component---src-pages-euro-gitterboxen-js" */),
  "component---src-pages-europaletten-js": () => import("./../../../src/pages/europaletten.js" /* webpackChunkName: "component---src-pages-europaletten-js" */),
  "component---src-pages-fertigung-mdx": () => import("./../../../src/pages/fertigung.mdx" /* webpackChunkName: "component---src-pages-fertigung-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kaminholz-js": () => import("./../../../src/pages/kaminholz.js" /* webpackChunkName: "component---src-pages-kaminholz-js" */),
  "component---src-pages-paletten-123-weg-js": () => import("./../../../src/pages/paletten-123-weg.js" /* webpackChunkName: "component---src-pages-paletten-123-weg-js" */),
  "component---src-pages-palettenmobel-js": () => import("./../../../src/pages/palettenmobel.js" /* webpackChunkName: "component---src-pages-palettenmobel-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-sonderpaletten-js": () => import("./../../../src/pages/sonderpaletten.js" /* webpackChunkName: "component---src-pages-sonderpaletten-js" */),
  "component---src-pages-streusalz-js": () => import("./../../../src/pages/streusalz.js" /* webpackChunkName: "component---src-pages-streusalz-js" */),
  "component---src-pages-uber-uns-mdx": () => import("./../../../src/pages/uber-uns.mdx" /* webpackChunkName: "component---src-pages-uber-uns-mdx" */),
  "component---src-pages-vermietung-reparaturen-js": () => import("./../../../src/pages/vermietung-reparaturen.js" /* webpackChunkName: "component---src-pages-vermietung-reparaturen-js" */)
}

